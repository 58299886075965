import { Backdrop, CircularProgress, Grid } from "@material-ui/core";
import React, { useEffect, useLayoutEffect, useState } from "react";
import CategoryFilter from "./CategoryFilter";
import CategoryChart from "./CategoryChart";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useMutation, useQuery } from "@apollo/client";
import { GET_DASHBOARD_PRODUCT_TYPE } from "../../../graphql/schemas/chart/chartQueries";
import swal from "sweetalert";
import moment from "moment";
import _ from "lodash";
import {
  ALL_SECONDARY_PRODUCT_STATUS,
  SECONDARY_PRODUCT_STATUS_DEPOSIT,
  SECONDARY_PRODUCT_STATUS_SOLDOTHER,
} from "../../../constants/defined";

ChartJS.register(
  CategoryScale,
  ChartDataLabels,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
      display: true,
    },
    title: {
      display: true,
      text: "Thống kê theo loại hình sản phẩm",
      fullSize: true,
      font: {
        size: "48px",
        weight: "normal",
      },
    },
    tooltip: {
      enabled: true,
      position: "nearest",
    },
    datalabels: {
      display: true,
      anchor: "end",
      align: "top",
    },
  },

  scales: {
    x: {
      grid: {
        drawOnChartArea: false,
        display: true,
        borderWidth: 1,
        drawBorder: false,
      },
    },
    y: {
      grid: {
        borderWidth: 1,
        drawBorder: false,
      },
    },
  },
  maintainAspectRatio: false,
};

function ChartCategory(props) {
  const { branches, status } = props;
  const [filtertType, setFilterType] = useState({
    branch: [],
    productType: [],
  });

  const [date, setDate] = useState({
    dateBegin: moment().startOf("month").format("YYYY/MM/DD").toString(),
    dateEnd: moment().endOf("month").format("YYYY/MM/DD").toString(),
  });

  const [variables, setVariables] = useState( {
    where: {
      date: {
        _gte: moment().startOf("month").format("YYYY/MM/DD").toString(),
        _lte: moment().endOf("month").format("YYYY/MM/DD").toString(),
      }
    },
  });

  const { loading, data, error } = useQuery(GET_DASHBOARD_PRODUCT_TYPE, {
    variables: variables,
    onCompleted: () => {
    },
    onError: (err) => {
      swal("Thông báo", err.message, "error");
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const handleSubmit = () => {
    const values = {
      ...filtertType,
      ...date,
    };

    if (
      _.toLower(_.get(values, "dateBegin")) !== _.toLower("Invalid date") &&
      _.toLower(_.get(values, "dateEnd")) !== _.toLower("Invalid date")
    ) {
      if (_.get(values, "dateBegin") > _.get(values, "dateEnd")) {
        swal(
          "Thông báo",
          "Ngày bắt đầu nhỏ hơn hoặc bằng ngày kết thúc",
          "error"
        );

        return;
      }

      const tmp = {
        where: {
          date: {
            _gte: _.get(values, "dateBegin"),
            _lte: _.get(values, "dateEnd"),
          },
          branch: {
            _in: _.get(values, "branch"),
          },
          product_status: {
            _in: _.get(values, "productType").find(
                (item) => item === "SOLD"
              )
                ? [
                    ...values.productType,
                    SECONDARY_PRODUCT_STATUS_DEPOSIT,
                    SECONDARY_PRODUCT_STATUS_SOLDOTHER,
                  ]
                : _.get(values, "productType"),
          },
        },
      };
  
      setVariables(tmp);
  
      _.get(values, "branch")?.length <= 0 && delete tmp.where.branch;
      _.get(values, "productType")?.length <= 0 && delete tmp.where.product_status;
      
      // refetch({
      //   where: {
      //     date: {
      //       _gte: _.get(values, "dateBegin"),
      //       _lte: _.get(values, "dateBegin"),
      //     },
      //   },
      // from_date: _.get(values, "dateBegin"),
      // to_date: _.get(values, "dateEnd"),
      // branch: _.get(values, "branch"),
      // product_status: _.get(values, "productType").find(
      //   (item) => item === "SOLD"
      // )
      //   ? [
      //       ...values.productType,
      //       SECONDARY_PRODUCT_STATUS_DEPOSIT,
      //       SECONDARY_PRODUCT_STATUS_SOLDOTHER,
      //     ]
      //   : _.get(values, "productType"),
      // });
    } else {
      swal(
        "Thông báo",
        "Ngày bắt đầu hoặc ngày kết thúc không hợp lệ",
        "error"
      );
    }
  };

  return (
    <Grid item container spacing={2}>
      <CategoryFilter
        onSubmit={handleSubmit}
        branches={branches}
        status={status}
        setFilterType={setFilterType}
        setDate={setDate}
        date={date}
      />
      <CategoryChart data={data} />
      <Backdrop
        style={{ color: "#fff", zIndex: 200000 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  );
}

export default ChartCategory;
