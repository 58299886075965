import _ from "lodash";
import { useEffect, useState } from "react";
import HeaderFilter from "../components/HeaderFilter";
import ListCheck from "../components/ListCheck";
import ListDate from "../components/ListDate";

const CategoryFilter = (props) => {
  const { branches, status, setFilterType, setDate, onSubmit, date } = props;
  const [showDate, setShowDate] = useState(false);
  const [statusList, setListStatus] = useState([]);
  const [showFilter, setShowFilter] = useState({
    productType: false,
    branch: false,
  });

  const LABLE = (status) =>
    _.get(
      {
        WAITING: "Chờ duyệt",
        SELLING: "Đang bán",
        SOLD: "Đã bán",
        HIDDEN: "Đã ẩn",
        LOCKED: "Đã khóa",
        ALL: "Xem tất cả",
      },
      status,
      ""
    );
  useEffect(() => {
    setListStatus(
      Object.keys(status)
        ?.map((item) => {
          return {
            id: item !== "all" && _.toUpper(item.toString()),
            name: item !== "all" && LABLE(item.toUpperCase()),
          };
        })
        .filter((item) => Boolean(item.id))
    );
  }, [status]);

  return (
    <HeaderFilter onSubmit={onSubmit} title="Thống kê theo loại hình sản phẩm">
      <ListDate
        setDate={setDate}
        date={date}
        open={showDate}
        setOpen={setShowDate}
      />
      <ListCheck
        headerTitle="Trạng thái"
        data={statusList}
        open={showFilter.productType}
        setOpen={setShowFilter}
        field="productType"
        setFilterType={setFilterType}
        checkAllTitle="Chọn tất cả trạng thái"
      />
      <ListCheck
        headerTitle="Chi nhánh"
        data={branches}
        open={showFilter.branch}
        setOpen={setShowFilter}
        setFilterType={setFilterType}
        field="branch"
        checkAllTitle="Chọn tất cả chi nhánh"
      />
    </HeaderFilter>
  );
};

export default CategoryFilter;
