import React, { useEffect, useRef, useState } from "react";
import { Backdrop, CircularProgress, Grid } from "@material-ui/core";
import GrowSecondaryChart from "./GrowSecondaryChart";
import GrowSecondaryFilter from "./GrowSecondaryFilter";
import swal from "sweetalert";
import moment from "moment";
import { GET_DASHBOARD_PRODUCT_SECONDARY } from "../../../graphql/schemas/chart/chartQueries";
import { useQuery } from "@apollo/client";
import _ from "lodash";
import {
  ALL_SECONDARY_PRODUCT_STATUS,
  SECONDARY_PRODUCT_STATUS_DEPOSIT,
  SECONDARY_PRODUCT_STATUS_SOLDOTHER,
} from "../../../constants/defined";

const GrowSecondary = (props) => {
  const chartRef = useRef(null);
  const { status, properties, branches } = props;

  const [variables, setVariables] = useState({
    where: {
      date: {
        _gte: moment().startOf("month").format("YYYY/MM/DD").toString(),
        _lte: moment().endOf("month").format("YYYY/MM/DD").toString(),
      },
      fk_property_type: {
        code: {
          _neq: "P",
        },
      },
    },
    where_aggregate: {
      date: {
        _lte: moment().endOf("month").format("YYYY/MM/DD").toString(),
      },
      fk_property_type: {
        code: {
          _neq: "P",
        },
      },
    },
  });

  const [filtertType, setFilterType] = useState({
    productType: [],
    propertyType: [],
    branch: [],
  });

  const [date, setDate] = useState({
    dateBegin: moment().startOf("month").format("YYYY/MM/DD").toString(),
    dateEnd: moment().endOf("month").format("YYYY/MM/DD").toString(),
  });

  const { loading, data, error, refetch } = useQuery(
    GET_DASHBOARD_PRODUCT_SECONDARY,
    {
      variables: variables,

      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
    }
  );

  const resetZoom = () => {
    chartRef.current.resetZoom();
  };

  const handleSubmit = async () => {
    resetZoom();
    const values = {
      ...filtertType,
      ...date,
    };

    if (
      _.toLower(_.get(values, "dateBegin")) !== _.toLower("Invalid date") &&
      _.toLower(_.get(values, "dateEnd")) !== _.toLower("Invalid date")
    ) {
      if (_.get(values, "dateBegin") > _.get(values, "dateEnd")) {
        swal(
          "Thông báo",
          "Ngày bắt đầu nhỏ hơn hoặc bằng ngày kết thúc",
          "error"
        );

        return;
      }

      const tmp = {
        where: {
          date: {
            _gte: _.get(values, "dateBegin"),
            _lte: _.get(values, "dateEnd"),
          },
          branch: {
            _in: _.get(values, "branch"),
          },
          product_status: {
            _in: _.get(values, "productType").find((item) => item === "SOLD")
              ? [
                  ...values.productType,
                  SECONDARY_PRODUCT_STATUS_DEPOSIT,
                  SECONDARY_PRODUCT_STATUS_SOLDOTHER,
                ]
              : _.get(values, "productType"),
          },
          id: {
            _in: _.get(values, "propertyType"),
          },
          fk_property_type: {
            code: {
              _neq: "P",
            },
          },
        },
        where_aggregate: {
          date: {
            _lte: _.get(values, "dateEnd"),
          },
          fk_property_type: {
            code: {
              _neq: "P",
            },
          },
        },
      };

      _.get(values, "branch")?.length <= 0 && delete tmp.where.branch;
      _.get(values, "productType")?.length <= 0 &&
        delete tmp.where.product_status;
      _.get(values, "property")?.length <= 0 && delete tmp.where.id;

      setVariables(tmp);

      // refetch({
      //   from_date: _.get(values, "dateBegin"),
      //   to_date: _.get(values, "dateEnd"),
      //   product_status: _.get(values, "productType").find(
      //     (item) => item === "SOLD"
      //   )
      //     ? [
      //         ...values.productType,
      //         SECONDARY_PRODUCT_STATUS_DEPOSIT,
      //         SECONDARY_PRODUCT_STATUS_SOLDOTHER,
      //       ]
      //     : _.get(values, "productType"),
      //   branch: _.get(values, "branch"),
      //   property_type: _.get(values, "propertyType"),
      // });
    } else {
      swal(
        "Thông báo",
        "Ngày bắt đầu hoặc ngày kết thúc không hợp lệ",
        "error"
      );
    }
  };
  return (
    <Grid item container spacing={2}>
      <GrowSecondaryFilter
        status={status}
        properties={properties}
        branches={branches}
        onSubmit={handleSubmit}
        setFilterType={setFilterType}
        setDate={setDate}
        date={date}
      />
      <GrowSecondaryChart date={date} chartRef={chartRef} dataChart={data} />
      <Backdrop style={{ color: "#fff", zIndex: 200000 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  );
};

export default GrowSecondary;
