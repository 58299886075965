import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar } from "react-chartjs-2";
import { Grid, makeStyles } from "@material-ui/core";
import { da } from "date-fns/locale";
import _ from "lodash";

ChartJS.register(
  CategoryScale,
  ChartDataLabels,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
      display: false,
    },
    tooltip: {
      enabled: true,
      position: "nearest",
    },
    datalabels: {
      display: true,
      font: {
        weight: "400",
      },
      color: "#000",
      anchor: "end",
      align: "top",
    },
  },

  scales: {
    x: {
      grid: {
        drawOnChartArea: false,
        display: true,
        borderWidth: 1,
        borderColor: "#000",
      },
    },
    y: {
      grace: "5%",
      grid: {
        borderWidth: 1,
        drawBorder: false,
      },
    },
  },
  maintainAspectRatio: false,
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: theme.palette.background.paper,
  },
  bar: {
    margin: theme.spacing(3),
  },
}));

function CategoryChart(props) {
  const { data } = props;
  const classes = useStyles();
  const [dataType, setDataType] = useState([]);
  const [backgroundColor, setBackgroundColor] = useState([]);

  const groupData = (arr, arg) => {
    let rs = [];
    arr?.forEach((item) => {
      if (_.get(item,"item.fk_property_type.code")  !== "P") {
        const index = rs
          .map((i) => _.get(i, arg))
          .findIndex((index) => index === _.get(item, arg));
        if (index != -1) {
          rs = rs.map((e, i) =>
            i === index ? { ...e, count: e.count + item.count } : e
          );
        } else {
          rs.push(item);
        }
      }
    });
    return rs;
  };

  useEffect(() => {
    setDataType(groupData(_.get(data,"result"), "fk_property_type.code").sort((a,b) =>_.get(b,"fk_property_type.code").localeCompare(_.get(a,"fk_property_type.code"))));
  }, [data]);
  

  useEffect(() => {
    const tmp = [];
    dataType?.forEach((item) => {
      if (_.get(item,"fk_property_type.code") === "T") {
        tmp.push("#0A2240");
      }
      if (_.get(item,"fk_property_type.code")  === "L") {
        tmp.push("#00837B");
      }
      if (_.get(item,"fk_property_type.code")  === "A") {
        tmp.push("#F4C867");
      }
      
    });
    setBackgroundColor(tmp);
  }, [dataType]);

  return (
    <Grid item container className={classes.wrapper}>
      <Bar
        height={500}
        options={options}
        data={{
          labels: dataType?.map((item) => _.get(item,"fk_property_type.name") ),
          datasets: [
            {
              label: "Thông tin",
              data: dataType?.map((item) => _.get(item,"count")) ,
              backgroundColor: backgroundColor,
              barThickness: 98,
            },
          ],
        }}
        className={classes.bar}
      />
    </Grid>
  );
}

export default CategoryChart;
