import * as _ from 'lodash';

export const BUY_TICKET = "BUY_TICKET";
export const BINGO_WEEKLY_RESULT = [
    'BINGO_WEEKLY_SPECIAL',
    'BINGO_WEEKLY_1',
    'BINGO_WEEKLY_2',
    'BINGO_WEEKLY_3',
    'BINGO_WEEKLY_KK',
];
export const BINGO_FINAL_RESULT = [
    'BINGO_FINAL_SPECIAL',
    'BINGO_FINAL_1',
    'BINGO_FINAL_2',
    'BINGO_FINAL_3',
    'BINGO_FINAL_KK',
];
export const BINGO_INPUT_WEEKLY_RESULTS = [
    'BINGO_WEEKLY_SPECIAL',
    'BINGO_WEEKLY_1',
    'BINGO_WEEKLY_2',
    'BINGO_WEEKLY_3',
    'BINGO_WEEKLY_KK', 'BINGO_WEEKLY_KK', 'BINGO_WEEKLY_KK', 'BINGO_WEEKLY_KK', 'BINGO_WEEKLY_KK',
    'BINGO_WEEKLY_KK', 'BINGO_WEEKLY_KK', 'BINGO_WEEKLY_KK', 'BINGO_WEEKLY_KK', 'BINGO_WEEKLY_KK',
];
export const BINGO_INPUT_FINAL_RESULTS = [
    'BINGO_FINAL_SPECIAL',
    'BINGO_FINAL_1',
    'BINGO_FINAL_2',
    'BINGO_FINAL_3',
    'BINGO_FINAL_KK', 'BINGO_FINAL_KK', 'BINGO_FINAL_KK', 'BINGO_FINAL_KK', 'BINGO_FINAL_KK',
    'BINGO_FINAL_KK', 'BINGO_FINAL_KK', 'BINGO_FINAL_KK', 'BINGO_FINAL_KK', 'BINGO_FINAL_KK',
];
export const BINGO_RESULT_SETTING_ITEM = {
    name: "",
    last_numbers: null,
    reward: null,
    remark: "",
    special: false,
    code: "",
};
export const PER_PAGE = 10;
export const URL_API_MAP = '/map/getmapbymultipoint';
export const COMING_SOON = {
    title: 'Thông báo',
    text: "Chức năng sẽ được cập nhật trong thời gian tới",
};
export const USER_ROLE_SUPPER_ADMIN = "SUPPER_ADMIN";
export const USER_ROLE_ADMINISTRATOR = "ADMINISTRATOR";
export const USER_ROLE_SALES = "SALES";
export const ACCESS_FUNCTION = ["CREATE", "VIEW", "UPDATE", "DELETE"];

// permission code 
export const CREATE_CUSTOMER = "CREATE_CUSTOMER";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const VIEW_CUSTOMER = "VIEW_CUSTOMER";
export const VIEW_PROMOTION = "VIEW_PROMOTION";
export const UPDATE_PROMOTION = "UPDATE_PROMOTION";
export const CREATE_PROMOTION = "CREATE_PROMOTION";
export const EXPORT_PRIMARY = "EXPORT_PRIMARY";
export const EXPORT_SECONDARY = "EXPORT_SECONDARY";
export const EXPORT_EMPLOYEE = "EXPORT_EMPLOYEE";
export const EXPORT_BRANCH = "EXPORT_BRANCH";
export const EXPORT_PROMOTION = "EXPORT_PROMOTION";
export const EXPORT_CUSTOMER = "EXPORT_CUSTOMER";
export const VIEW_BRANCH = "VIEW_BRANCH";
export const UPDATE_BRANCH = "UPDATE_BRANCH";
export const CREATE_BRANCH = "CREATE_BRANCH";
export const VIEW_EMPLOYEE = "VIEW_EMPLOYEE"
export const CREATE_EMPLOYEE = "CREATE_EMPLOYEE";
export const UPDATE_EMPLOYEE = "UPDATE_EMPLOYEE";
export const CREATE_PRIMARY = "CREATE_PRIMARY";
export const UPDATE_PRIMARY = "UPDATE_PRIMARY";
export const VIEW_PRIMARY = "VIEW_PRIMARY";
export const VIEW_SECONDARY = "VIEW_SECONDARY";
export const UPDATE_SECONDARY = "UPDATE_SECONDARY";
export const CREATE_NOTIFICATION = "CREATE_NOTIFICATION"
export const VIEW_NOTIFICATION = "VIEW_NOTIFICATION";
export const CREATE_BANNER = "CREATE_BANNER";
export const DELETE_BANNER = "DELETE_BANNER";
export const VIEW_BANNER = "VIEW_BANNER";
export const VIEW_DASHBOARD = "VIEW_DASHBOARD";
export const ALWAY_ALLOW_ACCESS = ["LOGOUT", "DASHBOARD"];
export const SECONDARY_PRODUCT_STATUS_WAITING = "WAITING";
export const SECONDARY_PRODUCT_STATUS_SELLING = "SELLING";
export const SECONDARY_PRODUCT_STATUS_DEPOSIT = "DEPOSIT";
export const SECONDARY_PRODUCT_STATUS_SOLD = "SOLD";
export const SECONDARY_PRODUCT_STATUS_SOLDOTHER = "SOLD_OTHER";
export const SECONDARY_PRODUCT_STATUS_HIDDEN = "HIDDEN";
export const SECONDARY_PRODUCT_STATUS_BLOCKED = "BLOCKED";
export const SECONDARY_PRODUCT_STATUS_LOCKED = "LOCKED";


export const ALL_SECONDARY_PRODUCT_STATUS = ["WAITING", "SELLING", "DEPOSIT", "SOLD", "SOLD_OTHER", "HIDDEN", "BLOCKED"];
export const LABLE_STATUS_PRODUCT_TAG = (status) => _.get({
    'WAITING': 'Chờ duyệt',
    'SELLING': 'Đang bán',
    'SOLD': 'Đã bán',
    'SOLD_OTHER': 'Đã bán ngoài hệ thống',
    'DEPOSIT': 'Đã bán',
    'HIDDEN': 'Đã ẩn',
    'LOCKED': 'Đã khóa',
}, status, '');
