import { useQuery } from "@apollo/client";
import { Backdrop, CircularProgress, Grid } from "@material-ui/core";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { GET_DASHBOARD_PRODUCT_TYPE_STATUS } from "../../../graphql/schemas/chart/chartQueries";
import ProductStatusChart from "./ProductStatusChart";
import ProductStatusFilter from "./ProductStatusFilter";

function ChartProductStatus(props) {
  const { branches, properties } = props;

  const [filtertType, setFilterType] = useState({
    branch: [],
    property: [],
  });

  const [params, setParams] = useState({
    branch: [],
    property_type: [],
  });

  const [variables, setVariables] = useState({ where: {} });

  const { loading, data, error, refetch } = useQuery(
    GET_DASHBOARD_PRODUCT_TYPE_STATUS,
    {
      variables: variables,
      onCompleted: () => {
      },
      onError: (err) => {
        swal("Thông báo", err.message, "error");
      },
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
    }
  );
  const handleSubmit = () => {
    const values = {
      ...filtertType,
    };

    const tmp = {
      where: {
        branch: {
          _in: _.get(values, "branch"),
        },
        id: {
          _in: _.get(values, "property"),
        },
      },
    };
    setVariables(tmp);

    _.get(values, "branch")?.length <= 0 && delete tmp.where.branch;
    _.get(values, "property")?.length <= 0 && delete tmp.where.id;

    // refetch({
    //   branch: _.get(values, "branch"),
    //   property_type: _.get(values, "property"),
    // });
  };

  return (
    <Grid item container spacing={2}>
      <ProductStatusFilter
        onSubmit={handleSubmit}
        branches={branches}
        properties={properties}
        setFilterType={setFilterType}
      />
      <ProductStatusChart dataChart={data} />
      <Backdrop
        style={{ color: "#fff", zIndex: 200000 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  );
}

export default ChartProductStatus;
