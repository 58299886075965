import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    paddingBlock: "20px !important",
  },
  wrapper: {
    position: "relative",
  },
  list: {
    position: "absolute",
    right: 0,
    top: -5,
    width: 334,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 6,
    boxShadow: "0px 0px 2px #B9B9B9",

    "&::before": {
      content: '"Bộ lọc"',
      position: "absolute",
      top: -20,
      fontSize: 12,
    },
  },
  subList: {
    maxHeight: 300,
    overflowY: "auto",
  },
  title: {
    fontWeight: 600,
    fontSize: 24,
    lineHeight: "30px",
  },
  item: {
    borderRadius: 6,
  },
  nonActive: {
    color: "#B9B9B9",
    "& svg": {
      color: "#B9B9B9",
    },
    border: "1px solid transparent",
  },
  active: {
    border: "1px solid #00837B",
  },
  shadow: {
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  collapse: {
    maxHeight: 580,
    "& > .MuiCollapse-wrapper": {
      maxHeight: "inherit",
    },
    "& > .MuiCollapse-wrapper > .MuiCollapse-wrapperInner": {
      maxHeight: "inherit",
      display: "flex",
      flexDirection: "column",
    },
  },
  content: {
    flex: 1,
    overflowY: "auto",
  },
  subHeader: {
    paddingLeft: 10,
    "& > span": {
      fontWeight: 600,
      lineHeight: "20px",
    },
  },
  button: {
    width: "100%",
    margin: 10,
    background: "#EF4036",
    fontWeight: 600,
    fontSize: 18,
    textTransform: "none",
    '&.MuiButton-containedSecondary:hover': {
      background: "#EF4036"
    }
  },
  checkBtn: {
    display: "inline-block",
    marginInline: 10,
    marginTop: 5,
  },
  dateWrap: {
    width: "100%",
    overflow: "hidden",
    margin: "12px 24px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    gap: 5,
  },
  date: {
    "& input": {
      paddingInline: 6,
    },
    "& ::-webkit-calendar-picker-indicator": {
      margin: 0,
    },
    "& label.Mui-focused": {
      color: "#00837B",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#00837B",
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: "#00837B",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#00837B",
      },
    },
  },
  datePicker: {
    backgroundColor: "#ffffff",
    border: "1px solid lightgray",
    padding: "5px 0px 5px 15px",
    borderRadius: "5px",
    margin: "0px",

    "& > div > div:last-child": {
      marginLeft: 0,

      "& > button": {
        padding: "8px",
      },
    },
  },
  note: {
    color: "#f00",
    
  },
}));
