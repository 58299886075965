import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar } from "react-chartjs-2";
import { Grid, makeStyles } from "@material-ui/core";
import _ from "lodash";
import {
  SECONDARY_PRODUCT_STATUS_HIDDEN,
  SECONDARY_PRODUCT_STATUS_LOCKED,
  SECONDARY_PRODUCT_STATUS_SELLING,
  SECONDARY_PRODUCT_STATUS_SOLD,
  SECONDARY_PRODUCT_STATUS_WAITING,
} from "../../../constants/defined";

ChartJS.register(
  CategoryScale,
  ChartDataLabels,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
      display: false,
    },
    tooltip: {
      enabled: true,
      position: "nearest",
    },
    datalabels: {
      display: true,
      font: {
        weight: "400",
      },
      color: "#000",
      anchor: "end",
      align: "top",
    },
  },

  scales: {
    x: {
      grid: {
        drawOnChartArea: false,
        display: true,
        borderWidth: 1,
        borderColor: "#000",
      },
    },
    y: {
      grace: "5%",
      grid: {
        borderWidth: 1,
        drawBorder: false,
      },
    },
  },
  maintainAspectRatio: false,
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: theme.palette.background.paper,
  },
  bar: {
    margin: theme.spacing(3),
  },
}));

function ProductStatusChart(props) {
  const { dataChart } = props;
  const classes = useStyles();
  const [dataType, setDataType] = useState([]);
  const [backgroundColor, setBackgroundColor] = useState([]);

  const groupData = (arr, arg) => {
    let rs = [];
    arr?.forEach((item) => {
      const index = rs
        .map((i) => _.get(i, arg))
        .findIndex((index) => index === _.get(item, arg));
      if (index != -1) {
        rs = rs.map((e, i) =>
          i === index ? { ...e, count: e.count + item.count } : e
        );
      } else {
        rs.push(item);
      }
    });
    return rs;
  };

  useEffect(() => {
    const tmp = [];
    groupData(_.get(dataChart, "result"), "product_status").forEach((item) => {
      switch (_.get(item, "product_status")) {
        case SECONDARY_PRODUCT_STATUS_WAITING:
          tmp.splice(0, 0, item);

          break;
        case SECONDARY_PRODUCT_STATUS_SOLD:
          tmp.splice(2, 0, item);

          break;
        case SECONDARY_PRODUCT_STATUS_SELLING:
          tmp.splice(1, 0, item);

          break;
        case SECONDARY_PRODUCT_STATUS_HIDDEN:
          tmp.splice(3, 0, item);

          break;
        case SECONDARY_PRODUCT_STATUS_LOCKED:
          tmp.splice(4, 0, item);

          break;

        default:
          break;
      }
    });
    setDataType(tmp);
  }, [dataChart]);

  useEffect(() => {
    const tmp = [];
    dataType?.forEach((item) => {
      if (_.get(item, "product_status") === SECONDARY_PRODUCT_STATUS_WAITING) {
        tmp.push("#0CA89E");
      }
      if (_.get(item, "product_status") === SECONDARY_PRODUCT_STATUS_SELLING) {
        tmp.push("#C7C70A");
      }
      if (_.get(item, "product_status") === SECONDARY_PRODUCT_STATUS_HIDDEN) {
        tmp.push("#E07E37");
      }
      if (_.get(item, "product_status") === SECONDARY_PRODUCT_STATUS_LOCKED) {
        tmp.push("#D0D0D0");
      }
      if (_.get(item, "product_status") === SECONDARY_PRODUCT_STATUS_SOLD) {
        tmp.push("#F4C867");
      }
    });
    setBackgroundColor(tmp);
  }, [dataType]);

  const LABLE = (status) =>
    _.get(
      {
        WAITING: "Chờ duyệt",
        SELLING: "Đang bán",
        SOLD: "Đã bán",
        HIDDEN: "Đã ẩn",
        LOCKED: "Đã khóa",
        ALL: "Xem tất cả",
      },
      status,
      ""
    );

  return (
    <Grid item container className={classes.wrapper}>
      <Bar
        height={500}
        options={options}
        data={{
          labels: dataType?.map((item) =>
            LABLE(_.get(item, "product_status").toUpperCase())
          ),
          datasets: [
            {
              label: "Thông tin",
              data: dataType?.map((item) => _.get(item, "count")),
              backgroundColor: backgroundColor,
            },
          ],
        }}
        className={classes.bar}
      />
    </Grid>
  );
}

export default ProductStatusChart;
