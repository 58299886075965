import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";
import { Grid, makeStyles } from "@material-ui/core";
import _ from "lodash";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

export const options = {
  responsive: true,
  barPercentage: 0.9,
  plugins: {
    legend: {
      position: "bottom",
      labels: {
        usePointStyle: true,
        pointStyle: "rectRounded",
      },
    },
    datalabels: {
      display: true,
      font: {
        weight: "400",
      },
      color: "#000",
      anchor: "end",
      align: "top",
    },
    zoom: {
      pan: {
        enabled: true,
        mode: "x",
      },
      limits: {
        y: { min: "original", max: "original" },
      },
    },
  },
  scales: {
    x: {
      min: 0,
      max: 8,
      grid: {
        drawOnChartArea: false,
        display: true,
        borderWidth: 1,
        borderColor: "#000",
      },
    },
    y: {
      grace: "5%",
      grid: {
        borderWidth: 1,
        drawBorder: false,
      },
    },
  },

  maintainAspectRatio: false,
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: theme.palette.background.paper,
  },
  bar: {
    margin: theme.spacing(3),
  },
}));

function CategoryAndLandChart(props) {
  const { dataChart, chartRef } = props;
  const classes = useStyles();

  const [dataType, setDataType] = useState([{}]);

  const groupData = (arr) => {
    let rs = [];
    arr?.forEach((item) => {
      const index = rs
        .map((i) => ({
          name: _.get(i, "name"),
          branch: _.get(i, "branch"),
          count: _.get(i, "count"),
        }))
        .findIndex(
          (index) =>
            index.name === _.get(item, "fk_property_type.code") &&
            index.branch === _.get(item, "fk_district.name")
        );
      if (index != -1) {
        rs = rs.map((e, i) =>
          i === index ? { ...e, count: e.count + item.count } : e
        );
      } else {
        rs.push({
          name: _.get(item, "fk_property_type.code"),
          branch: _.get(item, "fk_district.name"),
          count: _.get(item, "count"),
        });
      }
    });
    return rs;
  };

  useEffect(() => {
    const tmp = groupData(_.get(dataChart, "result"));
    let rs = [];
    tmp?.forEach((item) => {
      const index = rs
        .map((i) => _.get(i, "branch"))
        .findIndex((branch) => branch === _.get(item, "branch"));

      if (index !== -1) {
        rs = rs.map((e, i) =>
          i === index
            ? {
                ...e,
                values: {
                  ...e.values,
                  [item.name]: e.values[item.name]
                    ? e.values[item.name] + item.count
                    : item.count,
                  SUM: e.values.SUM + item.count,
                },
              }
            : e
        );
      } else {
        rs.push({
          branch: _.get(item, "branch"),
          values: {
            [item.name]: item.count,
            SUM: item.count,
          },
        });
      }
    });

    setDataType(rs);
  }, [dataChart]);

  return (
    <Grid item container className={classes.wrapper}>
      <Bar
        ref={chartRef}
        height={500}
        options={options}
        data={{
          labels: dataType?.map((item) => _.get(item, "branch")),
          datasets: [
            {
              label: "Nhà phố",
              data: dataType?.map((item) => _.get(item, "values.T")),
              backgroundColor: "#0A2240",
            },
            {
              label: "Đất nền",
              data: dataType?.map((item) => _.get(item, "values.L")),
              backgroundColor: "#00837B",
            },
            {
              label: "Căn hộ",
              data: dataType?.map((item) => _.get(item, "values.A")),
              backgroundColor: "#F4C867",
            },
            {
              label: "Tổng",
              data: dataType?.map((item) => _.get(item, "values.SUM")),
              backgroundColor: "#77BA6A",
            },
          ],
        }}
        className={classes.bar}
      />
    </Grid>
  );
}

export default CategoryAndLandChart;
